import axios from 'axios'
import utils from '@/utils/utils.js'

const SEARCH_PAGE = 12;

function getEnty(object, slug) {
    var entry = null
    Object.keys(object).forEach(key => {
        if (object[key][slug]) {
            entry = object[key][slug]
        }

    });
    return entry;
}
export default {
    namespaced: true,
    state: {
        results: {
            viewPage: 1,
            lastFilters: {
                textFilter: null,
                zone: null,
                theme: null,
                typeContent: null,
                dateStart : null,
                dateEnd :null
            },
            items: {},
            maxItems: null,
        },
    },
    getters: {
        getResults: (state) => (page) => {
            return state.results.items[page];
        },

        getResult: (state) => (slug) => {
            return getEnty(state.results.items, slug)
        },
        getResultsViewPage: (state) => {
            return state.results.viewPage;
        },
        getResultsLastFilters: (state) => {
            return state.results.lastFilters
        },
        getPagesResults: (state) => (itemsPerPage = SEARCH_PAGE) => {
            var pages = Math.ceil(state.results.maxItems / itemsPerPage)
            return pages;
        },
    },
    actions: {
        formContact({
            commit,
            dispatch
        }, data) {
            return new Promise((resolve, reject) => {
                axios.post('https://back.cermi.es/api/auto_contact/cermi-diario', data).then(response => {
                    resolve(response);
                })
            });
        },
        loadResults({
            commit,
            state
        }, {
            page = 1,
            length = 12,
            filtersResults = {
                textFilter : null,
                zone: null,
                theme: null,
                typeContent: null,
                dataStart :null,
                dataEnd : null
            }
        } = {}) {
            if (JSON.stringify(state.results.lastFilters) == JSON.stringify(filtersResults)) {
                if (state.results.items[page] != null && Object.keys(state.results.items[page]).length > 0) {
                    return;
                }
            } else {
                commit('cleanResults');
                page = 1;
            }
            if (state.results.maxItems !== null && Object.keys(state.results.items).length >= state.results.maxItems) {
                return;
            }
            var filtersSelect = [];
            if (filtersResults.theme) {
                filtersSelect.push(
                    {
                        'name': 'themes.id',
                        'search': filtersResults.theme.id
                    }
                )
            }
            if (filtersResults.zone) {
                filtersSelect.push(
                    {
                        'name': 'zone.id',
                        'search': filtersResults.zone.id
                    }
                )
            }
            if ((filtersResults.dataStart) || (filtersResults.dataEnd)){
                filtersSelect.push(
                    {
                        'name': 'publish_date',
                        'search': {
                            'min': filtersResults.dataStart,
                            'max': filtersResults.dataEnd
                        }
                    }
                )
            }
            var filters = utils.generateTextFilters(filtersResults.textFilter, ['name', 'header']);
            var data = {
                page: page,
                length: length,
                //filtersResults: filtersResults,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect.concat(filters),
                order: [
                    {
                    name: 'publish_date',
                    direction: 'desc'
                    }
                ],

            }
            //console.log(filtersResults)
            //Todas las acciones en commit
            //commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post(filtersResults.typeContent, data);
                promise.then(function (result) {
                    commit('addResults', {
                        results: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: filtersResults
                    });
                    resolve();
                    //commit('endLoad')
                });
            })
        },

    },
    modules: {},
    mutations: {
        addResults(state, {
            results,
            filters,
            page,
            maxItems
        }) {
            if (results) {
                results.forEach(element => {
                    if (state.results.items[page] == null) {
                        state.results.items[page] = {}
                    }
                    state.results.items[page][element.slug] = element;
                    //console.log(element)
                });
            }
            if (maxItems) {

                state.results.maxItems = maxItems;
            }
            //console.log(filters)
            if (filters) {
                state.results.lastFilters = filters
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.results.items[todelete] = {}
                }
            }

        },
        cleanResults(state) {
            state.results.viewPage = 1
            state.results.items = {}
            state.results.lastFilters = {
                zone: null,
                theme: null,
                typeContent: null,
            }
        },
        setPageResults(state, page) {
            state.results.viewPage = page
        },

    }
}