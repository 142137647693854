<template>
    <Header @filter="filterResult"></Header>
    <div class="body-app">
        <button id="INDmenu-btn" title="Herramienta de accesibilidad">
            <img alt="Herramienta de accesibilidad" title="Herramienta de accesibilidad" id="imgAcc" src="/img/accesibilidad-universal.png">
        </button>
        <transition name='custom-classes' enter-active-class="animate__animated animate__fadeIn animate__slow">
            <router-view :filterTime="this.filterTime" />
        </transition>
    </div>
    <Footer></Footer>
</template>

<style lang="scss">
    @import "@/styles/general.scss";
    @import "@/styles/components.scss";
    @import "@/styles/responsive.scss";
    @import url(https://kit.fontawesome.com/3500c0c80e.css);
</style>

<script>
    import {
        mapActions,
    } from "vuex";
    import Header from "@/components/header.vue";
    import Footer from "@/components/footer.vue";
    export default {
        name: "App",
        data() {
            return {
                filterTime: null,
            };
        },
        components: {
            Header,
            Footer,
        },
        mounted() {
            this.loadThemes();
            this.loadZones();
        },
        methods: {
            ...mapActions({
                loadThemes: 'contents/loadThemes',
                loadZones: 'contents/loadZones'
            }),
            filterResult() {
                this.filterTime = Date.now();
            }
        },
        watch: {

        },
    };
</script>

<style scoped lang="scss">
    .body-app {
        padding-top: 30px;
        min-height: 80vh;

        &.fixed-class {
            padding-top: 220px;
            /* transition: all 0.2s cubic-bezier(0, 1, 0.5, 1); */
        }
    }
</style>