<template lang="html">
  <header class="header">
    <section class="top-header">
      <div class="container">
        <span
          class="date"
          :title="currentDateTime(new Date())"
          :alt="currentDateTime(new Date())"
          >{{ currentDateTime(new Date()) }}</span
        >
        <div class="content">
          <div class="row-center" role="search">
            <button
              type="button"
              class="btn-search"
              title="Click para abrir barra de búsqueda abajo"
              @click="toggleShowSearchContainer"
              :showSearchContainer="showSearchContainer"
            ></button>
            <router-link
              title="Ir a Ir a página de búsqueda avanzada"
              alt="Ir a Ir a página de búsqueda avanzada"
              :to="{ name: 'advanced-search' }"
              class="btn-white"
              >Búsqueda avanzada</router-link
            >
          </div>
          <div class="rrss">
            <span title="Síguenos en" alt="Síguenos en">Síguenos en</span>
            <a
              href="https://www.facebook.com/Cermi-Estatal-338031889614106/"
              title="Link a Facebook"
              alt="Link a "
              target="_blank"
            >
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a
              href="https://twitter.com/Cermi_Estatal"
              title="Link a X"
              alt="Link a "
              target="_blank"
            >
              <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.instagram.com/cermi_estatal/"
              title="Link a Instagram"
              alt="Link a "
              target="_blank"
            >
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>

            <a
              href="https://es.linkedin.com/in/cermi-estatal-8ab39655"
              title="Link a Linkedin"
              alt="Link a Linkedin"
              target="_blank"
            >
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.youtube.com/user/CERMIESTATAL/videos"
              title="Link a Youtube"
              alt="Link a "
              target="_blank"
            >
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </a>
            <a
              href="https://t.me/cermiestatal"
              title="Link a Telegram"
              alt="Link a Telegram"
              target="_blank"
            >
              <i class="fa fa-telegram" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.tiktok.com/@cermiestatal"
              title="Link a Tiktok"
              alt="Link a Tiktok"
              target="_blank"
            >
              <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="nav-header">
      <router-link
        :to="{ name: 'home' }"
        title="CERMI. Innovación social en discapacidad."
        alt="CERMI. Innovación social en discapacidad."
        class="logo"
      >
        <img
          :src="urlLogo"
          alt="logo CERMI"
          title="CERMI. Innovación social en discapacidad."
        />
      </router-link>
      <div class="tool-search" role="search">
        <div class="container">
          <!-- <button type="button"  class="btn-search" aria-label="Click para abrir barra de búsqueda abajo"
            title="Click para abrir barra de búsqueda abajo" @click="toggleShowSearchContainer"
            :showSearchContainer="showSearchContainer"></button> -->
          <router-link
            title="Ir a Ir a página de búsqueda avanzada"
            alt="Ir a Ir a página de búsqueda avanzada"
            :to="{ name: 'advanced-search' }"
            class="btn-white"
            >Búsqueda avanzada</router-link
          >
        </div>
      </div>
      <div class="container-search" :showSearchContainer="showSearchContainer">
        <input
          type="text"
          placeholder="Buscar..."
          id="searchTool"
          v-model="textSearch"
          title="Buscar"
        />
        <button
          type="button"
          class="btn-search"
          @click="sendSearch"
          aria-label="Click para buscar"
          title="Click para buscar"
        ></button>
      </div>
      <ul class="nav-menu container">
        <button
          type="button"
          class="menu-respons"
          :showMenuResponsive="showMenuResponsive"
          @click="toggleShowSubMenuResponsive"
          aria-label="Abrir menú principal"
          title="Abrir menú principal"
        >
          Menú
        </button>
        <li
          class="container-responsiv"
          :showMenuResponsive="showMenuResponsive"
        >
          <ul>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'home' }"
                title="Ir a página de inicio"
                alt="Ir a página de inicio"
                >Inicio
              </router-link>
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'news' }"
                title="Ir a página de Actualidad"
                alt="Ir a página de Actualidad"
                :class="[
                  $route.params.typeContent == 'noticia'
                    ? 'router-link-active'
                    : '',
                ]"
                >Actualidad</router-link
              >
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'opinion' }"
                title="Ir a página de Opinión"
                alt="Ir a página de Opinión"
                :class="[
                  $route.params.typeContent == 'opinion'
                    ? 'router-link-active'
                    : '',
                ]"
                >Opinión</router-link
              >
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'interviews' }"
                title="Ir a página de entrevistas"
                alt="Ir a página de entrevistas"
                :class="[
                  $route.params.typeContent == 'entrevista'
                    ? 'router-link-active'
                    : '',
                ]"
                >Entrevistas</router-link
              >
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'reports' }"
                title="Ir a página de reportajes"
                alt="Ir a página de reportajes"
                :class="[
                  $route.params.typeContent == 'reportaje'
                    ? 'router-link-active'
                    : '',
                ]"
                >Reportajes</router-link
              >
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'audiovisuals' }"
                title="Ir a página de audiovisuales"
                alt="Ir a página de audiovisuales"
                :class="[
                  $route.params.typeContent == 'multimedia'
                    ? 'router-link-active'
                    : '',
                ]"
                >Imago CERMI
              </router-link>
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'documents' }"
                title="Ir a página de documentos"
                alt="Ir a página de documentos"
                :class="[
                  $route.params.typeContent == 'documento'
                    ? 'router-link-active'
                    : '',
                ]"
                >Documentos</router-link
              >
            </li>
            <li>
              <router-link
                class="vacio"
                :to="{ name: 'events' }"
                title="Ir a página de agenda"
                alt="Ir a página de agenda"
                :class="[
                  $route.params.typeContent == 'evento'
                    ? 'router-link-active'
                    : '',
                ]"
                >Agenda</router-link
              >
            </li>
            <!-- <li>
            <a target="_blank" class="vacio" href="https://www.fundacioncermimujeres.es/" title="Ir a página de Fundación Cermi Mujeres" alt="Ir a página de Fundación Cermi Mujere" >Fundación Cermi Mujeres <i class="fa fa-external-link" aria-hidden="true" style="color:#0069B4" ></i></a>
          </li> -->
          </ul>
          <div class="content responsive">
            <div class="row-center" role="search">
              <router-link
                title="Ir a Ir a página de búsqueda avanzada"
                alt="Ir a Ir a página de búsqueda avanzada"
                :to="{ name: 'advanced-search' }"
                class="btn-white"
                >Búsqueda avanzada</router-link
              >
            </div>
            <div class="rrss">
              <span title="Síguenos en" alt="Síguenos en">Síguenos en</span>
              <a
                href="https://www.facebook.com/Cermi-Estatal-338031889614106/"
                title="Link a Facebook"
                alt="Link a "
                target="_blank"
              >
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a
                href="https://twitter.com/Cermi_Estatal"
                title="Link a X"
                alt="Link a "
                target="_blank"
              >
                <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.instagram.com/cermi_estatal/"
                title="Link a Instagram"
                alt="Link a "
                target="_blank"
              >
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>

              <a
                href="https://es.linkedin.com/in/cermi-estatal-8ab39655"
                title="Link a Linkedin"
                alt="Link a Linkedin"
                target="_blank"
              >
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.youtube.com/user/CERMIESTATAL/videos"
                title="Link a Youtube"
                alt="Link a "
                target="_blank"
              >
                <i class="fa fa-youtube" aria-hidden="true"></i>
              </a>
              <a
                href="https://t.me/cermiestatal"
                title="Link a Telegram"
                alt="Link a "
                target="_blank"
              >
                <i class="fa fa-telegram" aria-hidden="true"></i>
              </a>
              <a
                href="https://www.tiktok.com/@cermiestatal"
                title="Link a Tiktok"
                alt="Link a "
                target="_blank"
              >
                <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </li>

        <li>
          <button
            type="button"
            class="openSubmenu"
            :showMenu="showMenu"
            title="Abrir submenú de temáticas"
            alt="Abrir submenú de temáticas"
            aria-label="Abrir submenú de temáticas"
            @click="toggleShowSubMenu"
          >
            Canales temáticos
          </button>
        </li>
      </ul>
    </section>

    <section class="submenu-tem container" :showMenu="showMenu">
      <div class="column-dest">
        <template
          v-for="(theme, key) in themeSpecials"
          :key="key"
          class="vacio"
        >
          <router-link
            class="vacio"
            v-if="key <= 1"
            @click="showMenu = false"
            :title="'Ir a temática ' + theme.name"
            :to="{ name: 'thematic-page', params: { slug: theme.slug } }"
          >
            <div class="content-img">
              <img class="vacio" :src="theme.icon.url" alt />
            </div>
            {{ theme.name }}
          </router-link>
        </template>
      </div>
      <div
        class="column-second"
        :class="[themeNormal.length > 8 ? 'list-style' : '']"
      >
        <template class="vacio" v-for="(theme, key) in themeNormal" :key="key">
          <router-link
            class="vacio"
            @click="showMenu = false"
            :title="'Ir a   temática ' + theme.name"
            :to="{ name: 'thematic-page', params: { slug: theme.slug } }"
          >
            <div class="content-img">
              <img class="vacio" :src="theme.icon.url" alt />
            </div>
            {{ theme.name }}
          </router-link>
        </template>
      </div>
    </section>
    <div class="alert-crespon container" v-if="$route.name == 'home' && crespon" tag="prod">
      <img src="/img/crespon.png" alt="Crespón luto" />
      <p class="title">
        En señal de luto por las víctimas de las lluvias e inundaciones en el
        sureste español
      </p>
    </div>
    <section
      class="filters-component container"
      v-show="
        $route.name != 'advanced-search' &&
        $route.name != 'contact' &&
        $route.name != 'editorial-team' &&
        $route.name != 'page-entry' &&
        $route.name != 'accessibility' &&
        $route.name != 'legal-notice' &&
        ($route.name != 'privacy') & ($route.name != 'thematic-page')
      "
    >
      <span class="label-tag">Filtrar por:</span>
      <select class="theme" v-model="themeSelected">
        <optgroup>
          <option :value="null" title="null">{{ cleanTextTheme }}</option>
          <template v-for="theme in themes" :key="theme.id">
            <option :value="theme" :title="theme.name">{{ theme.name }}</option>
          </template>
        </optgroup>
      </select>
      <p class="title-cat-big" v-if="themeSelected">{{ themeSelected.name }}</p>
      <!-- <div class="filters-component location">
        <select class="location" v-model="zoneSelected">
          <optgroup>
            <option :value="null" title="null">{{ cleanTextZone }}</option>
            <template v-for="zone in zones" :key="zone.id">
              <option :value="zone" :title="zone.name">{{ zone.name }}</option>
            </template>
          </optgroup>

        </select>
      </div> -->
    </section>
  </header>
</template>

<script lang="js">
import moment from 'moment'
import store from '@/store';
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
export default {
  name: 'header_vue',
  data() {
    return {
      newLogo: false,
      urlLogo:'/img/logo.jpg',
      crespon:true,
      showMenu: false,
      showMenuResponsive: false,
      showSearchContainer: false,
      zoneSelected: null,
      textSearch: '',
      themeSelected: null,
    }
  },
  computed: {
    ...mapGetters({
      themeSpecials: 'contents/getThemesSpecials',
      themeNormal: 'contents/getThemesNormal',
      zones: 'contents/getZones',
      zone: 'contents/getZoneSelected',
      themes: 'contents/getThemes',
      theme: 'contents/getThemeSelected',
      loading: 'contents/getLoading',

    }),
    themeBySlug: function () {
      return store.getters['contents/getThemeSlug'](this.$route.params.slug);
    },
    themeByQuery: function () {
      return store.getters['contents/getThemeSlug'](this.$route.query.theme);
    },
    cleanTextZone() {
      if (this.zone != null) {
        return 'Eliminar selección'
      } else {
        return 'Seleccionar territorio'
      }
    },
    cleanTextTheme() {
      if (this.theme != null) {
        return 'Eliminar selección'
      } else {
        return 'Seleccionar temática'
      }
    }
  },
  methods: {
    ...mapMutations({
      setZone: 'contents/setZone',
      setTheme: 'contents/setTheme',
    }),
    ...mapActions({
      loadResults: 'form/loadResults'
    }),
    currentDateTime(date) {
      return moment(date).locale('es').format('LL')
    },
    toggleShowSubMenu: function () {
      this.showMenu = !this.showMenu;
      if (this.showMenuResponsive == true) {
        this.showMenuResponsive = false
      }
    },
    toggleShowSubMenuResponsive: function () {
      this.showMenuResponsive = !this.showMenuResponsive;
      if (this.showMenu == true) {
        this.showMenu = false
      }
    },
    toggleShowSearchContainer: function () {
      this.showSearchContainer = !this.showSearchContainer;
    },
    topHeader: function () {
      window.onscroll = () => {
        if (window.scrollY > 0) {
          document.querySelector('header').classList.add('fixed-class')
          document.querySelector('.body-app').classList.add('fixed-class')
        } else {
          document.querySelector('header').classList.remove('fixed-class')
          document.querySelector('.body-app').classList.remove('fixed-class')
        }
      }
    },
    sendSearch: function () {
      this.$router.push({
        name: 'advanced-search',
        params: {
          text: this.textSearch,
        }
      })
      this.loadResults({
        // length: 12,
        page: this.page,
        filtersResults: {
          'textFilter': this.textSearch,
          'typeContent': 'entries',
        }
      });
      this.showSearchContainer = false
      this.textSearch = ''
      return
    }
  },
  mounted() {
    this.topHeader();
    this.zoneSelected = this.zone;
    this.themeSelected = this.theme;
    const fechaActual = new Date().getTime();
    const fechaFin = new Date(2024, 10, 3).getTime();
    if (fechaActual >= fechaFin) {
      this.crespon = false
    }

  },
  watch: {
    zoneSelected(newZone, oldZone) {
      this.setZone(this.zoneSelected);
      this.$emit('filter', {});
    },
    themeSelected(newTheme, oldTheme) {
      this.$router.push({ name: this.$route.name })
      this.setTheme(this.themeSelected);
      this.$emit('filter', {});
    },
    //If anyother page change the filter
    theme(newTheme, oldTheme) {
      if (newTheme !== oldTheme) {
        this.themeSelected = newTheme;
      }
    },
    "$route.path"() {
      this.showMenu = false
      this.showMenuResponsive = false
      this.showSearchContainer = false
      if (this.$route.params.slug) {
        this.setTheme(this.themeBySlug)
      } else if (this.$route.query.theme) {
        this.setTheme(this.themeByQuery)
      } else {
        this.setTheme(null)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/styles/colours.scss";

.top-header {
  position: relative;
  z-index: 3;
  background: white;
  transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

  .btn-search {
    opacity: 0;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    display: none;
  }

  .btn-white {
    opacity: 0;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    display: none;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $blueLight;
    padding: 15px 0;
    align-items: center;
  }

  span {
    font-family: "Frank Ruhl Libre", serif;
    color: $blue;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2em;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 750px;

    .btn-blue {
      margin: 0 10px;
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    }
  }
}

.rrss {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  span {
    font-family: "Frank Ruhl Libre", serif;
    color: $blue;
    font-weight: bold;
  }

  a {
    background: $blue;
    border-radius: 50%;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border: 1px solid $blue;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    &:hover {
      border: 1px solid $blue;
      background: white;
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

      .fa,
      .fa-brands {
        color: $blue;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      }
    }

    .fa,
    .fa-brands {
      color: white;
      font-size: 15px;
    }
  }
}

.nav-header {
  position: relative;
  z-index: 3;
  background: white;

  .logo {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    position: relative;
    z-index: 1;
    text-decoration: none;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

    .btn-orange {
      margin-right: 25px;

      &:hover {
        border: 1px solid $orange;
        color: white;
        background: $orange;
      }
    }

    img {
      max-width: 230px;
      width: 100%;
    }
  }

  .tool-search {
    position: relative;
    z-index: 1;
    background: white;

    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 7px;
    }
  }

  .container-search {
    width: 100%;
    background: $orange;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    margin-bottom: -50px;
    height: 50px;

    &[showSearchContainer="true"] {
      transform: translateY(0);
      transition: all 0.5s ease-in-out;
      margin-bottom: 0px;
      opacity: 1;
    }

    input {
      border-radius: 33px;
      background: white;
      font-size: 16px;
      color: $blue;
      border: 0;
      padding: 5px 15px;
      width: 100%;
      max-width: 400px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $blue;
      }
    }

    .btn-search {
      background-image: url(/img/icons/search-white.svg);
    }
  }

  .nav-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 15px 0;
    border-bottom: 1px solid $blueLight;

    a {
      text-decoration: none;
      font-family: "Frank Ruhl Libre", serif;
      font-size: 18px;

      &.router-link-active {
        font-weight: 700;
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 5px;
          border-radius: 10px;
          background: $blue;
          position: absolute;
          right: 0;
          left: 0;
          margin: 0 auto;
          bottom: -5px;
        }
      }
    }

    .container-responsiv {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 215px);
      padding-right: 40px;

      .content.responsive {
        display: none;
      }
    }

    .menu-respons {
      font-family: "Frank Ruhl Libre", serif;
      font-weight: 700;
      border: 0;
      font-size: 18px;
      background: transparent;
      position: relative;
      cursor: pointer;
      padding-left: 45px;
      display: none;

      &:before {
        content: "";
        width: 40px;
        height: 40px;
        display: inline-block;
        background-image: url(/img/icons/icon_menu_black.svg);
        background-size: 40px;
        background-repeat: no-repeat;
        background-position: left center;
        position: absolute;
        left: 0;
        bottom: -5px;
      }

      &[showmenuresponsive="true"] {
        position: relative;

        &:after {
          content: "";
          width: 32px;
          height: 4px;
          border-radius: 5px;
          background: $blue;
          position: absolute;
          bottom: -10px;
          right: 11px;
          /* left: 0; */
          margin: 0 auto;
        }
      }
    }

    .openSubmenu {
      color: $blue;
      font-family: "Frank Ruhl Libre", serif;
      font-weight: 700;
      border: 0;
      font-size: 18px;
      background: transparent;
      position: relative;
      cursor: pointer;
      padding-right: 45px;

      &:before {
        content: "";
        width: 40px;
        height: 40px;
        display: inline-block;
        background-image: url(/img/icons/icon_menu.svg);
        background-size: 40px;
        background-repeat: no-repeat;
        background-position: right center;
        position: absolute;
        right: 0;
        bottom: -5px;
      }
    }
  }
}

.btn-search {
  border: 1px solid $orange;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url("/img/icons/search-orange.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
  transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

  &:focus {
    outline: none;
  }

  &:hover,
  &[showsearchcontainer="true"] {
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    background-image: url("/img/icons/search-white.svg");
    background-color: $orange;
  }

  &[showsearchcontainer="true"] {
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $orange transparent;
      position: absolute;
      bottom: -14px;
    }
  }
}

.submenu-tem {
  transform: translateY(-103%);
  display: flex;
  background: rgba(0, 105, 180, 0.95);
  padding: 30px;
  position: absolute;
  right: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  z-index: 2;

  &[showmenu="true"] {
    transition: all 0.5s ease-in-out;
    transform: translateY(0);
    z-index: 2;
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Frank Ruhl Libre", serif;
    font-size: 18px;
    font-weight: 700;

    img {
      object-fit: cover;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }

  .column-dest {
    width: 25%;
    border-right: 1px solid white;
    padding-right: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    a {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    img {
      margin-right: 20px;
    }
  }

  .column-second {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75%;

    a {
      width: calc(100% / 4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
    }

    .content-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 50%;
        height: auto;
        border-radius: 0;
      }
    }

    &.list-style {
      justify-content: flex-start;
      padding: 0 40px;
      align-items: flex-start;

      a {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;

        .content-img {
          display: none;
        }
      }
    }
  }
}

header {
  transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

  &.fixed-class {
    background: white;
    transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    -webkit-box-shadow: 0px 6px 21px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 21px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 6px 21px -12px rgba(0, 0, 0, 0.75);

    .filters-component select,
    .filters-component .label-tag {
      margin-bottom: 0;
    }

    .title-cat-big {
      display: none;
    }

    .filters-component {
      padding-bottom: 10px;
      margin-top: 10px;

      &.location {
        padding-bottom: 0px;
        margin-top: 0px;
      }
    }

    .filters-component select.location {
      /* background-image: url(/img/icons/icon-map-white.svg);
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        background-color: $blue;
        color: white; */
      /* border-bottom: 1px solid white; */

      /* option {
          color: white;
        } */
    }

    .top-header {
      transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      background: $blue;
      z-index: 4;

      /* border-radius: 0 0 10px 10px; */
      .container {
        padding: 10px 0;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      }

      span {
        color: white;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      }

      .btn-blue,
      .rrss a {
        background: white;
        color: $blue;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

        .fa,
        .fa-brands {
          color: $blue;
          transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        }
      }

      .btn-search {
        opacity: 1;
        background-image: url(/img/icons/search-white.svg);
        border-color: white;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        display: block;

        &:hover,
        &[showsearchcontainer="true"] {
          border-color: $orange;
        }
      }

      .btn-white {
        opacity: 1;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        display: block;
      }
    }

    .nav-header {
      .logo,
      .tool-search {
        display: none;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
      }
    }
  }

  .filters-component select.location {
    /* margin-bottom: 0; */
  }
}
.alert-crespon {
  background: #ebf3f9;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-wrap: wrap;
  img {
    max-width: 75px;
    margin-right: 25px;
  }
  p{
    text-align: center;
  }
}
.header.fixed-class .alert-crespon {
  display: none;
}
@media screen and (max-width: 1280px) {
  .top-header .content {
    max-width: 535px;
  }
}

@media screen and (max-width: 1060px) {
  .container-grid .grid-big {
    width: 60%;
  }

  .container-grid .grid-small {
    width: 40%;
  }

  .slider-news-featured .agile__actions {
    width: calc(100% - 310px);
  }

  .nav-header .nav-menu a.router-link-active,
  .nav-header .nav-menu a,
  .nav-header .nav-menu .openSubmenu,
  .top-header span,
  .nav-header .nav-menu .menu-respons {
    font-size: 16px;
  }

  .nav-header .nav-menu {
    padding: 10px 0;
  }

  .top-header .content {
    max-width: calc(100% - 220px);
  }

  .top-header .content {
    max-width: 100%;
    margin-top: 10px;
    display: none;
  }

  .fixed-class {
    .top-header .content {
      display: flex;
    }
  }

  .submenu-tem .column-dest a {
    flex-direction: column;
  }

  .nav-header .nav-menu .container-responsiv {
    padding-right: 0;
  }
}

@media screen and (max-width: 845px) {
  .nav-header .nav-menu .menu-respons {
    display: block;
    padding-left: 30px;
  }

  .nav-header .nav-menu .openSubmenu {
    padding-right: 30px;
  }

  .nav-header .nav-menu .menu-respons:before,
  .nav-header .nav-menu .openSubmenu:before {
    background-size: 28px;
    bottom: -8px;
  }

  .nav-header .nav-menu .container-responsiv {
    width: 100%;
    position: absolute;
    flex-direction: column;
    top: 43px;
    display: flex;
    background: #dcebf6;
    padding: 30px;
    position: absolute;
    transition: all 0.5s ease-in-out;
    transform: translateX(-100%);
    right: 0;
    left: 0;
    z-index: 2;

    li {
      text-align: center;
      margin-bottom: 15px;
    }

    &[showmenuresponsive="true"] {
      transform: translateX(0%);
    }
  }

  .nav-header .nav-menu {
    position: relative;
  }

  .submenu-tem {
    transform: translateX(103%);
  }

  .submenu-tem[showmenu="true"] {
    transition: all 0.5s ease-in-out;
    transform: translateX(0);
    z-index: 2;
    right: initial;
    left: initial;
  }

  .openSubmenu[showmenu="true"] {
    position: relative;

    &:after {
      content: "";
      width: 32px;
      height: 4px;
      border-radius: 5px;
      background: $blue;
      position: absolute;
      bottom: -10px;
      left: 30px;
      margin: 0 auto;
    }
  }

  .nav-header .nav-menu .container-responsiv .content.responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn-white {
      margin-bottom: 20px;
    }
  }

  .nav-header .tool-search .container {
    display: none;
  }

  .nav-header .logo .btn-orange {
    display: none;
  }

  .nav-header .logo {
    padding-top: 0;
  }

  .filters-component select.location,
  .filters-component select {
    font-size: 15px;
    padding-right: 5px;
    padding-left: 22px;
    background-size: 21px;
    margin-right: 0;
  }

  .filters-component .label-tag {
    font-size: 16px;
    background-size: 19px;
    padding-left: 20px;
  }

  .fixed-class .top-header .content {
    display: none;
  }

  .submenu-tem {
    flex-wrap: wrap;
  }

  .submenu-tem {
    .column-dest {
      width: 100%;
      border-right: 0;
    }

    .column-second {
      border-top: 1px solid white;
      padding-top: 20px;
      width: 100%;
    }
  }

  .submenu-tem .column-dest a {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .submenu-tem .column-second a {
    width: 50%;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 10px;
  }

  .submenu-tem .column-second .content-img {
    display: none;
  }

  .submenu-tem a img {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .rrss {
    justify-content: center;

    span {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
</style>
