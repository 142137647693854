<template lang="html">
  <footer class="footer">
    <agile :options="myOptions" ref="carousel" class="logos-box container">
      <a target="_blank" href="https://www.exteriores.gob.es/ " title="Logotipo de El Goberno de España - Ministerio de Asuntos sociales, unión europea y cooperación. Abre una ventana nueva.">
        <img src="/img/logo-maex.svg" alt="Logotipo de El Goberno de España - Ministerio de Asuntos sociales, unión europea y cooperación. Abre una ventana nueva." />
      </a>
      <a target="_blank" title="Logotipo Gobierno de España - Ministerio de Derechos Sociales, Consumo y Agenda 2030. Abre una ventana nueva." href="https://www.mdsocialesa2030.gob.es/">
        <img src="/img/logo-derechos.svg" alt="Logotipo Gobierno de España - Ministerio de Derechos Sociales, Consumo y Agenda 2030. Abre una ventana nueva." />
      </a>
      <a target="_blank" href="http://fundaciononce.es" title="Logotipo de la Fundación ONCE. Abre una ventana nueva.">
        <img src="/img/logo-fundacion-once.svg" alt="Logotipo de la Fundación ONCE. Abre una ventana nueva." />
      </a>
      <a target="_blank" href="https://www.hablamosdeeuropa.es/es/Paginas/Home.aspx " title="Logotipo de la Hablamos de Europa Abre una ventana nueva.">
        <img src="/img/logo-hablamos.png" alt="Logotipo de la Hablamos de Europa Abre una ventana nueva." style="max-width:87px" />
      </a>
    </agile>
    <section class="links-footer container">
      <router-link :to="{ name: 'home' }" title="CERMI. Innovación social en discapacidad." alt="CERMI. Innovación social en discapacidad." class="logo colum-footer">
        <img :src="urlLogo" alt="CERMI. Innovación social en discapacidad." title="CERMI. Innovación social en discapacidad." />
      </router-link>
      <section class="colum-footer">
        <router-link class="vacio" :to="{ name: 'editorial-team' }" title="Ir a página de Equipo Editorial" alt="Ir a página de Equipo Editorial">Equipo Editorial</router-link>
        <router-link class="vacio" :to="{ name: 'contact' }" title="Ir a página de Contacto" alt="Ir a página de Contacto">Contacto
        </router-link>
        <router-link class="vacio" :to="{ name: 'advanced-search' }" role="search" title="Ir a página de Búsqueda avanzada" alt="Ir a página de Búsqueda avanzada">Búsqueda avanzada</router-link>
      </section>
      <section class="colum-footer">
        <router-link class="vacio" :to="{ name: 'privacy' }" title="Ir a página de Privacidad" alt="Ir a página de Privacidad">Régimen
          de
          Privacidad</router-link>
        <router-link class="vacio" :to="{ name: 'accessibility' }" title="Ir a página de Accesibilidad" alt="Ir a página de Accesibilidad">Accesibilidad</router-link>
        <router-link class="vacio" :to="{ name: 'legal-notice' }" title="Ir a página de Aviso legal" alt="Ir a página de Aviso legal">
          Aviso legal</router-link>

      </section>
      <div class="rrss colum-footer">
        <span title="Síguenos en" alt="Síguenos en">Síguenos en</span>
        <a href="https://www.facebook.com/Cermi-Estatal-338031889614106/" title="Link a Facebook" alt="Link a " target="_blank">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/Cermi_Estatal" title="Link a X" alt="Link a " target="_blank">
          <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
        </a>
        <a href="https://www.instagram.com/cermi_estatal/" title="Link a Instagram" alt="Link a " target="_blank">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>

        <a href="https://es.linkedin.com/in/cermi-estatal-8ab39655" title="Link a Linkedin" alt="Link a Linkedin" target="_blank">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a href="https://www.youtube.com/user/CERMIESTATAL/videos" title="Link a Youtube" alt="Link a " target="_blank">
          <i class="fa fa-youtube" aria-hidden="true"></i>
        </a>
        <a href="https://t.me/cermiestatal" title="Link a Telegram" alt="Link a Telegram" target="_blank">
          <i class="fa fa-telegram" aria-hidden="true"></i>
        </a>
        <a href="https://www.tiktok.com/@cermiestatal" title="Link a Tiktok" alt="Link a Tiktok" target="_blank">
          <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
        </a>
      </div>
    </section>
  </footer>
</template>

<script lang="js">
  import {
    VueAgile
  } from "vue-agile";

  export default {
    name: 'footer_vue',
    mounted() {

      const fechaActual = new Date().getTime();
      const fechaFin = new Date(2023, 6, 15).getTime();
      if (fechaActual >= fechaFin) {
        this.urlLogo = '/img/logo.jpg'
      }

    },
    data: () => ({
      urlLogo: '/img/logo_orgullo.jpg',
      myOptions: {
        responsive: [{
          breakpoint: 900,
          settings: {
            navButtons: false,
            slidesToShow: 4,
            dots: false,
            centerMode: true,
            unagile: true,
          },
        }, {
          breakpoint: 800,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: true,
          },
        }, {
          breakpoint: 0,
          settings: {
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            autoplay: true,
          },
        },],
      },
    }),
    components: {
      agile: VueAgile,
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/colours.scss";

  .logos-box {
    padding: 30px 0;
    border-top: 1px solid $blueLight;
    border-bottom: 1px solid $blueLight;

    a {
      margin: 0;
      display: inline-block;
      text-align: center;
    }
  }

  .links-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 0 20px 0;

    .colum-footer {
      width: 20%;

      a,
      span {
        font-family: "Frank Ruhl Libre", serif;
        color: $blue;
        width: 100%;
        display: inline-block;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
        margin-bottom: 5px;
        line-height: 1.2em;
      }
    }

    .logo {
      img {
        max-width: 200px;
      }
    }

    .rrss {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      a {
        background: $blue;
        border-radius: 50%;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;
        border: 1px solid $blue;
        margin-top: 10px;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

        &:last-of-type {
          margin-right: 0px;
        }

        &:hover {
          border: 1px solid $blue;
          background: white;
          transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

          .fa,
          .fa-brands {
            color: $blue;
          }
        }

        .fa,
        .fa-brands {
          color: white;
          font-size: 15px;
          line-height: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1060px) {

    .links-footer .colum-footer a,
    .links-footer .colum-footer span {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 900px) {
    .links-footer .colum-footer {
      width: 30%;
    }

    .logo.colum-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }

  }

  @media screen and (max-width: 845px) {
    .links-footer .colum-footer {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .links-footer .colum-footer {
      text-align: center;
    }

    .links-footer .colum-footer a {
      width: 23%;
    }

    .links-footer .rrss a {
      width: 30px;
    }
  }

  @media screen and (max-width:750px) {
    .links-footer .colum-footer a {
      width: 100%;
    }

    .links-footer .rrss a {
      width: 30px;
    }

    .logos-box {
      margin-top: 25px;
    }
  }
</style>