import contents from './contents';
import form from './form';
import {
    createStore
} from 'vuex'
export default createStore({
    modules: {
        contents: contents,
        form: form
    }
})