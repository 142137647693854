import {
    createRouter,
    createWebHistory
} from 'vue-router'
const routes = [
    {
        path: '/',
        name: 'home',
        props: true,
        meta: {
            title: 'CERMI Diario| El Digital Global de la Discapacidad',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/home.vue')
    },
    {
        path: '/actualidad',
        name: 'news',
        meta: {
            title: 'CERMI Diario| Actualidad',
            description: 'Mantente al día de la actualidad con CERMI diario, el digital global de la discapacidad.'
        },
        component: () =>
            import('@/views/news.vue'),

    },
    {
        path: '/opinion',
        name: 'opinion',
        meta: {
            title: 'CERMI Diario| Opinión',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/opinion.vue'),

    },

    {
        path: '/entrevistas',
        name: 'interviews',
        meta: {
            title: 'CERMI Diario| Entrevistas',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/interviews.vue'),

    },
    {
        path: '/reportajes',
        name: 'reports',
        meta: {
            title: 'CERMI Diario| Reportajes',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/reports.vue'),

    },
    {
        path: '/articulos-generales',
        name: 'others',
        meta: {
            title: 'CERMI Diario| Artículos Generales',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/others.vue'),

    },
    {
        path: '/audiovisuales',
        name: 'audiovisuals',
        meta: {
            title: 'CERMI Diario| Audiovisuales',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/audiovisuals.vue'),
    },
    {
        path: '/documentos',
        name: 'documents',
        meta: {
            title: 'CERMI Diario| Documentos',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/documents.vue'),

    },
    {
        path: '/busqueda-avanzada',
        name: 'advanced-search',
        props: true,
        component: () =>
            import('@/views/advanced-search.vue'),

    },
    {
        path: '/agenda',
        name: 'events',
        meta: {
            title: 'CERMI Diario| Agenda',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        props: true,
        component: () =>
            import('@/views/events.vue'),

    },
    {
        path: '/contacto',
        name: 'contact',
        meta: {
            title: 'Contacto CERMI | Dónde Estamos y Cómo Llegar ',
            description: 'Contacta con nosotros vía telefónica o vía formulario. Te esperamos. 91 360 16 78'
        },
        component: () =>
            import('@/views/contact.vue'),

    },
    {
        path: '/equipo-editorial',
        name: 'editorial-team',
        props: true,
        component: () =>
            import('@/views/editorial-team.vue'),
    },
    {
        path: '/tematica/:slug',
        name: 'thematic-page',
        props: true,
        meta: {
            title: 'CERMI Diario| Temática',
            description: 'CERMI diario, el digital global de la discapacidad, órgano de expresión y comunicación del Comité Español de Representantes de Personas con Discapacidad (CERMI)'
        },
        component: () =>
            import('@/views/thematic-page.vue'),
    },
    {
        path: '/:typeContent/:slug',
        name: 'page-entry',
        component: () =>
            import('@/views/page-entry.vue'),
        props: true,
    },
    {
        path: '/accesibilidad',
        name: 'accessibility',
        component: () =>
            import('@/views/accessibility.vue'),
    },
    {
        path: '/aviso-legal',
        name: 'legal-notice',
        component: () =>
            import('@/views/legal-notice.vue'),
    },
    {
        path: '/privacidad',
        name: 'privacy',
        component: () =>
            import('@/views/privacy.vue'),
    },
    {
        path: '/test',
        name: 'test',
        component: () =>
            import('@/views/test.vue'),
    },
    {
        path: '/:pathMatch(.*)',
        redirect: to => {
            return {
                path: '/',
                query: {
                    httpstatus: 404,
                }
            }
        }
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes
})
router.beforeEach((to, from, next) => {
    let meta = to.meta
    if (!!meta.title && !!meta.description) {
        document.title = meta.title
        document.head.querySelector("[name=description]").content = meta.description;
        document.head.querySelector("[property='og:title']").content = meta.title;
        document.head.querySelector("[property='og:description']").content = meta.description;
        document.head.querySelector("[property='og:url']").content = location.href;
    }
    if (to.query.httpstatus) {
        let metaComponent = document.createElement('meta');
        metaComponent.name = "prerender-status-code";
        metaComponent.content = to.query.httpstatus;
        document.head.appendChild(metaComponent);
        if (to.query.httpstatus == 301) {
            metaComponent = document.createElement('meta');
            metaComponent.name = "prerender-header";
            metaComponent.content = "Location: " + location.href;
            document.head.appendChild(metaComponent);
        }
        delete to.query.httpstatus;
    }
    next()
})

export default router