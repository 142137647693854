export default {
    generateTextFilters(text, columns, textFilter = 1) {
        var filters = [];
        var column = null;
        if (text == null) {
            return filters;
        }
        for (column of columns) {
            filters.push({
                filterPack: true,
                filters: [{
                    name: column,
                    search: text,
                    textFilter: textFilter,
                }],
            })
        }
        return filters;
    },
    LoaderScrollToTop(position) {
        setTimeout(function() {
            let element = document.getElementById("app");
            element.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }, 200);
        return position
    }
}